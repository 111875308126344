import React from "react";
import styled from "styled-components";

export const Divider = ({data}) => {
  const { color, line_style, padding_top, padding_bottom, thickness, width } = data || {};
  
  const dividerStyles = {
    width: `${width}%`,
    marginTop: `${padding_top}px`,
    marginBottom: `${padding_bottom}px`,
    border: 'none',
    borderBottom:  `1px solid #000`,
    borderBottomColor: `${color}`,
    borderBottomStyle: `${line_style}`,
    borderBottomWidth: `${thickness}px`,
  }
 
  if(!width || !thickness || !color || !line_style) return null;

  return (
    <StyledTextComponent className="divider-block">
      <div className='content' style={dividerStyles}></div>
    </StyledTextComponent>
  );
};

const StyledTextComponent = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
