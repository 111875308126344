import React from "react";
import styled from "styled-components";

export const Spacer = ({data}) => {
  const { height } = data || {};
 
  if(!height) return null;

  return (
    <StyledTextComponent className="spacer-block">
      <div className='content' style={{height: `${height}px`}}></div>
    </StyledTextComponent>
  );
};

const StyledTextComponent = styled.div`
  width: 100%;
`;
