import React from "react";
import Select from "react-select";

import styled from "styled-components";

export const Dropdown = ({data}) => {
  const {title, items } = data || {};
 
  const options = items?.map((item) => ({
    value: item.url, 
    label: item.item_label,   
  })) || [];

  const selectStyles = {
    container: (provided) => ({ ...provided, width: 300 }),
    control: (provided, state) => ({
      ...provided,
      border: "1px solid #d4d4d4",  
      boxShadow: "none",  
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: 4, 
    }),
    indicatorSeparator: () => ({ display: "none" }),  
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#f9f9f9",  
      boxShadow: "0px 8px 16px 0px rgba(0,0,0,0.2)",  
      padding: "12px 0px",  
    }),
    option: (provided, state) => ({
      ...provided,
      paddingLeft: "16px", 
      paddingRight: "16px",  
    }),
  }

  const handleChange = (selectedOption) => {
    if (selectedOption?.value) { 
      window.open(selectedOption.value, '_blank');  
    }
  };
  
  if(!items?.length ) return null;

  return (
    <StyledDropdownComponent className="dropdown-block">
      <Select
        isSearchable
        options={options} 
        onChange={handleChange} 
        styles={selectStyles}
        classNamePrefix="dropdown-select" 
        placeholder={title || 'Select option'}
        components={{ IndicatorSeparator: () => null }}  
      />
    </StyledDropdownComponent>
  );
};

const StyledDropdownComponent = styled.div`
  width: 100%;
  padding: 20px; 
  font-family: ${(props) => props.theme.block_title_font?.split('=')[1]?.replace(/\+/g, ' ')};
  color: ${(props) => props.theme.block_title_font_color};
  display: flex; 
  justify-content: center;
  .title { 
    margin-bottom: 12px;
    font-size: 22px;
  } 
`;
